import React from 'react';
import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  LinkedinShareButton,
  TwitterIcon,
  LinkedinIcon,
} from 'react-share';
import { Link, graphql } from 'gatsby';
import { Layout, SEO } from 'components/common';
import { Header, HeaderBackground } from 'components/theme';
import {
  Article,
  Banner,
  BlogHeadContent,
  Bullet,
  HeaderWrapper,
  BannerWrapper,
  HeaderTitleContent,
  PostMetadata,
  Title,
  PostMetadataWrapper,
  AuthorWrapper,
  AuthorTextWrapper,
  AuthorImage,
  PrevNextArticleNav,
  Container,
  ShareButtons,
} from './styles';

const BlogPostTemplate = ({ data, pageContext }: any) => {
  const post = data.markdownRemark;
  const { previous, next } = pageContext;

  return (
    <Layout>
      <HeaderBackground />
      <SEO
        title={post.frontmatter.title}
        description={post.frontmatter.description || post.excerpt}
      />
      <Header product="advertising" />
      <Article>
        {post.frontmatter.author ? (
          <AuthorBannerHeader {...post.frontmatter} />
        ) : (
          <BannerHeader {...post.frontmatter} />
        )}
        <Container>
          <section dangerouslySetInnerHTML={{ __html: post.html }} />
        </Container>
      </Article>

      <Container>
        <PrevNextArticleNav>
          <ul>
            <li>
              {previous && (
                <Link to={previous.fields.slug} rel="prev">
                  ← {previous.frontmatter.title}
                </Link>
              )}
            </li>
            <li>
              {next && (
                <Link to={next.fields.slug} rel="next">
                  {next.frontmatter.title} →
                </Link>
              )}
            </li>
          </ul>
        </PrevNextArticleNav>
      </Container>
    </Layout>
  );
};

const BannerHeader: React.FC<any> = props => (
  <>
    <BannerWrapper>
      <Banner
        fluid={props.banner.childImageSharp.fluid}
        alt={props.bannerAlt}
      />
    </BannerWrapper>
    <Container>
      <div className="row">
        <BlogHeadContent
          banner={Boolean(props.banner)}
          full
          className="col-xl-12 col-lg-12 col-md-12 col-sm-12"
        >
          <HeaderTitleContent>
            <Title centered>{props.title}</Title>
          </HeaderTitleContent>
          <PostMetadataWrapper>
            <PostMetadata centered>
              <SocialMediaShareButtons />
              <span>
                {props.date}
                {'  '}
                <Bullet>•</Bullet>
                {'  '}
                {props.minutes} min read
              </span>
            </PostMetadata>
            <div className="share-buttons-inline"></div>
          </PostMetadataWrapper>
        </BlogHeadContent>
      </div>
    </Container>
  </>
);

const AuthorBannerHeader: React.FC<any> = props => (
  <Container>
    <HeaderWrapper centered className="row">
      <BlogHeadContent
        full={!props.banner}
        banner={Boolean()}
        className={
          props.banner
            ? 'col-xl-5 col-lg-5 col-md-12 col-sm-12'
            : 'col-xl-12 col-lg-12 col-md-12 col-sm-12'
        }
      >
        <HeaderTitleContent>
          <Title centered={!props.banner}>{props.title}</Title>
          <PostMetadata centered={!props.banner}>
            <SocialMediaShareButtons />
            <span>
              {props.date}
              {'  '}
              <Bullet>•</Bullet>
              {'  '}
              {props.minutes} min read
            </span>
          </PostMetadata>
          <AuthorWrapper>
            <AuthorImage
              fluid={props.authorImg.childImageSharp.fluid}
              alt={props.author}
            />
            <AuthorTextWrapper>
              <div>Written by {props.author}</div>
              <a href="https://www.instagram.com/itspaneau/">@itspaneau</a>
            </AuthorTextWrapper>
          </AuthorWrapper>
        </HeaderTitleContent>
        <div className="share-buttons"></div>
      </BlogHeadContent>
      {Boolean(props.banner) && (
        <BannerWrapper className="col-xl-7 col-lg-7 col-md-12 col-sm-12">
          <Banner
            fluid={props.banner.childImageSharp.fluid}
            alt={props.bannerAlt}
          />
        </BannerWrapper>
      )}
    </HeaderWrapper>
  </Container>
);

const SocialMediaShareButtons: React.FC = () =>
  typeof window !== 'undefined' ? (
    <ShareButtons>
      <TwitterShareButton url={window.location.href}>
        <TwitterIcon size={40} round />
      </TwitterShareButton>
      <FacebookShareButton url={window?.location.href}>
        <FacebookIcon size={40} round />
      </FacebookShareButton>
      <LinkedinShareButton url={window?.location.href}>
        <LinkedinIcon size={40} round />
      </LinkedinShareButton>
    </ShareButtons>
  ) : null;

export default BlogPostTemplate;

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
        minutes
        banner {
          childImageSharp {
            fluid(maxWidth: 900) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        bannerAlt
        author
        authorImg {
          childImageSharp {
            fluid(maxWidth: 80) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`;
